export function getShopAlias(shopName: string) {
  // if shop name looks like an acronym, return the first two letters
  // otherwise return the first initial of the first two words
  return /^[A-Z]{2}/.test(shopName)
    ? shopName.slice(0, 2)
    : shopName
        .split(/[^A-Za-z]+/)
        .map((n) => n.slice(0, 1))
        .slice(0, 2)
        .join('')
        .toUpperCase();
}
