import { computed, inject, Injectable, signal } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FetchAuthSessionOptions } from '@aws-amplify/core';
import {
  confirmSignIn,
  confirmUserAttribute,
  fetchAuthSession,
  sendUserAttributeVerificationCode,
  signIn,
  signOut,
  updatePassword,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { Subject } from 'rxjs';

import { parseIdToken } from '@rpm/shared/auth';
import { Claims } from '@rpm/shared/schemas';

import { PresentationService } from '../presentation/presentation.service';

export interface AuthSession {
  token: string;
  claims: Claims;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly signedIn$ = new Subject<void>();
  readonly signedOut$ = new Subject<void>();

  private presentationService = inject(PresentationService);

  async getSession(options?: FetchAuthSessionOptions) {
    const session = await fetchAuthSession(options);
    if (!session.tokens?.idToken) {
      return undefined;
    }

    try {
      const claims = parseIdToken(session.tokens.idToken.payload);
      const authSession: AuthSession = {
        token: session.tokens.idToken.toString(),
        claims,
      };
      this._session.set(authSession);
      return authSession;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
      await this.signOut();
      return undefined;
    }
  }

  private _session = signal<AuthSession | undefined>(undefined);

  session = computed(() => {
    const session = this._session();
    if (!session) {
      throw new Error('Session not loaded');
    }
    return session;
  });

  constructor() {
    Hub.listen('auth', ({payload}) => {
      switch (payload.event) {
        case 'signedIn':
          this.signedIn$.next();
          break;
        case 'signedOut':
          this.signedOut$.next();
          break;
      }
    });
  }


  async signOut() {
    this.presentationService.stop();
    await signOut();
  }

  changePassword(oldPassword: string, newPassword: string) {
    return updatePassword({ oldPassword, newPassword });
  }

  async confirmEmail(confirmationCode: string) {
    await confirmUserAttribute({
      userAttributeKey: 'email',
      confirmationCode,
    });
    await this.getSession({ forceRefresh: true });
  }

  signIn(username: string, password: string) {
    return signIn({ username, password });
  }

  confirmSignIn(newPassword: string) {
    return confirmSignIn({ challengeResponse: newPassword });
  }

  resendEmailConfirmationCode() {
    return sendUserAttributeVerificationCode({ userAttributeKey: 'email' });
  }
}

export const resolveSession: ResolveFn<AuthSession | undefined> = () =>
  inject(AuthService).getSession();
