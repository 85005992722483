import { computed, inject } from '@angular/core';
import { signalStoreFeature, withComputed, withState } from '@ngrx/signals';

import { OrgStore } from './org.store';

interface OrgState {
  _loadedAllInOrgId: string | null;
}

const initialState: OrgState = {
  _loadedAllInOrgId: null,
};

export function withOrgId() {
  return signalStoreFeature(
    withState(initialState),
    withComputed((_store, orgStore = inject(OrgStore)) => ({
      orgId: computed(() => {
        const orgId = orgStore.selectedEntityId();
        if (typeof orgId !== 'string') {
          throw new Error('No org selected!');
        }
        return orgId;
      }),
    })),
  );
}

export function setLoadedAllInOrgId(orgId: string): OrgState {
  return { _loadedAllInOrgId: orgId };
}
