<h1 mat-dialog-title>Presentation Mode</h1>

<form [formGroup]="form" (submit)="submit()">
  <div mat-dialog-content class="flex flex-col gap-4">
    <mat-form-field>
      <mat-label>Display group</mat-label>
      <mat-select formControlName="group">
        @for (group of orgStore.selectedEntity()?.groups; track group) {
          <mat-option [value]="group"> {{ group }} </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-slide-toggle formControlName="privateMode"
      >&nbsp;Private mode</mat-slide-toggle
    >
    <div class="text-body-sm text-secondary">
      Enable to hide net profit, expenses, and fixed operation costs.
    </div>

    <app-form-group-error />
  </div>

  <div mat-dialog-actions align="end">
    <button type="button" mat-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-button color="primary" [disabled]="form.invalid">
      Start
    </button>
  </div>
</form>
