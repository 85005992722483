import { computed } from '@angular/core';
import {
  patchState,
  signalStore,
  type,
  withComputed,
  withMethods,
} from '@ngrx/signals';
import {
  entityConfig,
  removeEntities,
  removeEntity,
  SelectEntityId,
  setAllEntities,
  setEntities,
  withEntities,
} from '@ngrx/signals/entities';
import { diff } from 'radashi';

import { ShopRole, ShopUsers, UserShops } from '@rpm/shared/schemas';

interface UserShop {
  userId: string;
  shopId: string;
  userName: string;
  shopName: string;
  role: ShopRole;
}

const selectId: SelectEntityId<Pick<UserShop, 'userId' | 'shopId'>> = ({
  userId,
  shopId,
}) => `${userId}:${shopId}`;

const config = entityConfig({
  entity: type<UserShop>(),
  collection: '_userShop',
  selectId,
});

export const UserShopStore = signalStore(
  { providedIn: 'root' },
  withEntities(config),
  withComputed((store) => ({
    byUser: computed(() =>
      store._userShopEntities().reduce<Record<string, UserShops>>(
        (grouped, { userId, shopId, shopName, role }) => ({
          ...grouped,
          [userId]: {
            ...grouped[userId],
            [shopId]: { name: shopName, role },
          },
        }),
        {},
      ),
    ),
    byShop: computed(() =>
      store._userShopEntities().reduce<Record<string, ShopUsers>>(
        (grouped, { userId, shopId, userName, role }) => ({
          ...grouped,
          [shopId]: {
            ...grouped[shopId],
            [userId]: { name: userName, role },
          },
        }),
        {},
      ),
    ),
  })),
  withMethods((store) => ({
    setAll(userShops: UserShop[]) {
      patchState(store, setAllEntities(userShops, config));
    },
    setAllForUser(user: { id: string; name: string }, userShops: UserShops) {
      const existingShopIds = Object.keys(store.byUser()[user.id] ?? {});
      const removeShopIds = diff(existingShopIds, Object.keys(userShops));
      const removeIds = removeShopIds.map((shopId) =>
        selectId({ userId: user.id, shopId }),
      );

      patchState(
        store,
        setEntities(
          Object.entries(userShops).map(
            ([shopId, { name: shopName, role }]) => ({
              userId: user.id,
              userName: user.name,
              shopId,
              shopName,
              role,
            }),
          ),
          config,
        ),
        removeEntities(removeIds, config),
      );
    },
    setAllForShop(shop: { id: string; name: string }, shopUsers: ShopUsers) {
      const existingUserIds = Object.keys(store.byShop()[shop.id] ?? {});
      const removeUserIds = diff(existingUserIds, Object.keys(shopUsers));
      const removeIds = removeUserIds.map((userId) =>
        selectId({ userId, shopId: shop.id }),
      );

      patchState(
        store,
        setEntities(
          Object.entries(shopUsers).map(
            ([userId, { name: userName, role }]) => ({
              userId,
              userName,
              shopId: shop.id,
              shopName: shop.name,
              role,
            }),
          ),
          config,
        ),
        removeEntities(removeIds, config),
      );
    },
    remove(key: Pick<UserShop, 'userId' | 'shopId'>) {
      patchState(store, removeEntity(selectId(key), config));
    },
    removeAllForUser(userId: string) {
      patchState(
        store,
        removeEntities((item) => item.userId === userId, config),
      );
    },
    removeAllForShop(shopId: string) {
      patchState(
        store,
        removeEntities((item) => item.shopId === shopId, config),
      );
    },
  })),
);

// interface ShopUserEntity {
//   id: string;
//   users: ShopUsers;
// }
//
// interface UserShopEntity {
//   id: string;
//   shops: UserShops;
// }
// export const UserShopStore = signalStore(
//   { providedIn: 'root' },
//   withEntities({ entity: type<ShopUserEntity>(), collection: 'byShop' }),
//   withEntities({ entity: type<UserShopEntity>(), collection: 'byUser' }),
//   withMethods((store) => ({
//     set(userShop: UserShop) {
//       patchState(store, setEntity(userShop, {collection: 'byShop'}));
//     },
//     remove({ userId, shopId }: { userId: string; shopId: string }) {
//       patchState(
//         store,
//         updateEntity(
//           {
//             id: userId,
//             changes: ({ shops }) => ({ shops: omit(shops ?? {}, [shopId]) }),
//           },
//           { collection: 'byUser' },
//         ),
//         updateEntity(
//           {
//             id: shopId,
//             changes: ({ users }) => ({ users: omit(users ?? {}, [userId]) }),
//           },
//           { collection: 'byShop' },
//         ),
//       );
//     },
//   })),
// );
