import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  filter,
  firstValueFrom,
  fromEvent,
  map,
  merge,
  Observable,
  shareReplay,
  startWith,
  Subject,
} from 'rxjs';

import { Shop } from '@rpm/shared/schemas';

import { getShopAlias } from './get-shop-alias';
import { PresentationConfigDialog } from './presentation-config.dialog';

export interface PresentationConfig {
  orgId: string;
  group: string;
  privateMode: boolean;
  showLegend: boolean;
}

const popupWidth = 400;
const popupHeight = 500;

const STORAGE_KEY = 'PRESENTATION';

@Injectable({ providedIn: 'root' })
export class PresentationService {
  private dialog = inject(MatDialog);

  private subject = new Subject<PresentationConfig | false>();

  readonly value$ = merge(
    this.subject,
    // watch storage in case presentation mode is triggered from other tab/window
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter((event) => event.key === STORAGE_KEY),
      map((event) => parseStorageValue(event.newValue)),
      startWith(parseStorageValue(localStorage.getItem(STORAGE_KEY))),
    ),
  ).pipe(shareReplay(1));

  private popup: Window | null = null;

  async toggle() {
    if (await firstValueFrom(this.value$)) {
      this.stop();
    } else {
      PresentationConfigDialog.configure(this.dialog).subscribe((config) => {
        if (config) {
          this.start(config);
        }
      });
    }
  }

  start(config: PresentationConfig): void {
    if (config.showLegend) {
      this.openLegendPopup();
    }
    this.updateValue(config);
  }

  stop(): void {
    this.closeLegendPopup();
    this.updateValue(false);
  }

  openLegendPopup(): void {
    this.popup = window.open(
      '/presentation-legend',
      'User Legend',
      `resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, width=${popupWidth}, height=${popupHeight}`,
    );
  }

  closeLegendPopup(): void {
    if (this.popup) {
      this.popup.close();
      this.popup = null;
    }
  }

  getShopNameOrAlias(
    shop: Pick<Shop, 'id' | 'name' | 'groups'>,
  ): Observable<string> {
    return this.shouldAliasShop(shop).pipe(
      map((shouldAlias) =>
        shouldAlias ? getShopAlias(shop.name) : shop.name,
      ),
    );
  }

  shouldAliasShop(shop: Pick<Shop, 'id' | 'name' | 'groups'>) {
    return this.value$.pipe(
      map((config) => shouldAliasShop(shop, config))
    )
  }

  private updateValue(value: PresentationConfig | false) {
    this.subject.next(value);
    if (value) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  }
}

function shouldAliasShop(
  shop: Pick<Shop, 'groups'>,
  config: PresentationConfig | false,
): boolean {
  return config && !shop.groups?.includes(config.group);
}

function parseStorageValue(value: string | null): PresentationConfig | false {
  return value ? (JSON.parse(value) as PresentationConfig) : false;
}
