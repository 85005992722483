import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { CreateOrgBody, Org, UpdateOrgBody } from '@rpm/shared/schemas';

const apiUrl = import.meta.env.NG_APP_API_URL;

interface UpdateOrgArgs extends UpdateOrgBody {
  id: string;
}

@Injectable({ providedIn: 'root' })
export class OrgService {
  private http = inject(HttpClient);

  getAll() {
    return this.http.get<Org[]>(`${apiUrl}/orgs`);
  }

  createOrg(body: CreateOrgBody) {
    return this.http.post<Org>(`${apiUrl}/orgs`, body);
  }

  updateOrg({ id, ...body }: UpdateOrgArgs) {
    return this.http.put<Org>(`${apiUrl}/orgs/${id}`, body);
  }

  deleteOrg({ id }: { id: string }) {
    return this.http.delete<void>(`${apiUrl}/orgs/${id}`);
  }
}
