import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Observable } from 'rxjs';

import { ShopStore } from '../admin/shops/shop.store';
import { OrgStore } from '../org/org.store';
import { FormGroupErrorComponent } from '../shared/form/form-group-error.component';

import { PresentationConfig } from './presentation.service';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './presentation-config.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogTitle,
    FormsModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatSlideToggle,
    FormGroupErrorComponent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class PresentationConfigDialog implements OnInit {
  private dialogRef =
    inject<MatDialogRef<void, PresentationConfig>>(MatDialogRef);
  private fb = inject(FormBuilder);

  readonly orgStore = inject(OrgStore);
  readonly shopStore = inject(ShopStore);

  static configure(
    dialog: MatDialog,
  ): Observable<PresentationConfig | undefined> {
    return dialog
      .open<PresentationConfigDialog, void, PresentationConfig>(
        PresentationConfigDialog,
        {
          disableClose: true,
          width: '400px',
          autoFocus: 'dialog', // focusing select input causes it to display invalid styling
        },
      )
      .afterClosed();
  }

  readonly form = this.fb.nonNullable.group({
    group: ['', Validators.required],
    privateMode: true,
  });

  constructor() {
    effect(() => {
      const groups = this.orgStore.selectedEntity()?.groups;

      if (groups?.length === 1) {
        this.form.controls.group.setValue(groups[0]);
        this.form.controls.group.disable();
      } else {
        this.form.controls.group.enable();
      }
    });
  }

  ngOnInit() {
    this.shopStore.loadAllInOrg(this.shopStore.orgId);
  }

  submit(): void {
    const { group, privateMode } = this.form.getRawValue();
    if (!group) {
      return;
    }

    const allGroups = this.orgStore.selectedEntity()?.groups ?? [];

    this.dialogRef.close({
      orgId: this.shopStore.orgId(),
      group,
      privateMode,
      showLegend: allGroups.length > 1,
    });
  }
}
