import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class AppTitleService {
  private title = inject(Title);

  resetTitle() {
    this.title.setTitle('RPM');
  }

  setTitle(title: string): void {
    this.title.setTitle(`${title} · RPM`);
  }

  getTitle(): string {
    return this.title.getTitle();
  }
}
