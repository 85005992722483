import { isNullish } from 'radashi';
import { JsonObject } from 'type-fest';

import { Claims } from '../schemas/auth/claims';
import { assert } from '../utility';

export function parseIdToken(payload: JsonObject): Claims {
  const {
    sub,
    email,
    name,
    email_verified: emailVerified,
    'custom:role': role,
    'custom:orgId': orgId,
    'custom:groups': groups,
    'custom:shops': shops,
    'custom:chatwootHmac': chatwootHmac,
  } = payload;

  assert(typeof sub === 'string', 'Token must contain sub');
  assert(typeof name === 'string', 'Token must contain name');
  assert(typeof email === 'string', 'Token must contain email');
  assert(typeof orgId === 'string', 'Token must contain orgId');
  assert(typeof role === 'string' || isNullish(role), 'role must be a string');
  assert(
    typeof groups === 'string' || isNullish(groups),
    'groups must be a string',
  );
  assert(typeof shops === 'string' || isNullish(shops), 'shops must be a string');

  return {
    userId: sub,
    email,
    emailVerified: !!emailVerified,
    name,
    role: role as Claims['role'],
    orgId,
    groups: groups ? (JSON.parse(groups) as Claims['groups']) : undefined,
    shops: shops ? (JSON.parse(shops) as Claims['shops']) : undefined,
    chatwootHmac: typeof chatwootHmac === 'string' ? chatwootHmac : undefined,
  };
}