import { Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  imports: [MatIcon],
})
export class ErrorComponent {
  message = input<string>();
}
