import { Injectable } from '@angular/core';
import { isEmpty, template } from 'radashi';

import { getErrorMessage } from './get-error-message';

interface ErrorMessages {
  [key: string]: string | ((data: unknown) => string);
}

const defaultErrorMessage = 'Sorry, something went wrong';

@Injectable({
  providedIn: 'root',
})
export class FormErrorService {
  readonly errorMessages: ErrorMessages = {
    auth: getAuthErrorMessage,
    incorrectPassword: 'Incorrect password',

    email: 'Invalid email address',
    required: 'Required',
    max: 'Cannot be more than {{max}}',
    min: 'Cannot be less than {{min}}',
    minlength: 'Enter at least {{requiredLength}} characters',
    pattern: 'Invalid',
    int: 'Enter an integer',

    enableAtLeastOne: 'Enable at least one {{itemLabel}}',

    systemError: getErrorMessage,
    unknownError: defaultErrorMessage,
  };

  describeValidationErrors(
    errors: Record<string, unknown>,
  ): string | undefined {
    if (isEmpty(errors)) {
      return undefined;
    }

    // 1. Use the first error with a template
    const key = Object.keys(errors).find((key) => this.errorMessages[key]);
    if (key) {
      const message = this.errorMessages[key];
      return typeof message === 'function'
        ? message(errors[key])
        : template(message, errors[key] as Record<string, unknown>);
    }

    // 2. Otherwise use the first error with a `message` property
    const errorWithMessage = Object.values(errors).find(hasMessage);
    if (errorWithMessage) {
      return String(errorWithMessage.message);
    }

    // 3. Otherwise use the first error key
    return Object.keys(errors)[0];
  }
}

function hasMessage(e: unknown): e is { message: unknown } {
  return typeof e === 'object' && !!e && 'message' in e;
}

function getAuthErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message.replace(/password did not conform with policy: /i, '');
  } else {
    return 'Something went wrong';
  }
}
