import { getDnsSuffix } from '../../clients/endpoints/getDnsSuffix.mjs';
import '../../types/errors.mjs';
import '../../errors/errorHelpers.mjs';
import { unauthenticatedHandler } from '../../clients/handlers/aws/unauthenticated.mjs';
import { jitteredBackoff } from '../../clients/middleware/retry/jitteredBackoff.mjs';
import { getRetryDecider } from '../../clients/middleware/retry/defaultRetryDecider.mjs';
import '../../utils/getClientInfo/getClientInfo.mjs';
import '../../utils/retry/retry.mjs';
import '../../parseAWSExports.mjs';
import 'uuid';
import { AmplifyUrl } from '../../utils/amplifyUrl/index.mjs';
import '../../singleton/Auth/utils/errorHelpers.mjs';
import '@aws-crypto/sha256-js';
import '@smithy/util-hex-encoding';
import { getAmplifyUserAgent } from '../../Platform/index.mjs';
import '../../Platform/types.mjs';
import '../../BackgroundProcessManager/types.mjs';
import '../../Reachability/Reachability.mjs';
import '../../Hub/index.mjs';
import '../../utils/sessionListener/index.mjs';
import { composeTransferHandler } from '../../clients/internal/composeTransferHandler.mjs';
import { parseJsonError } from '../../clients/serde/json.mjs';
import { observeFrameworkChanges } from '../../Platform/detectFramework.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * The service name used to sign requests if the API requires authentication.
 */
const SERVICE_NAME = 'cognito-identity';
/**
 * The endpoint resolver function that returns the endpoint URL for a given region.
 */
const endpointResolver = ({
  region
}) => ({
  url: new AmplifyUrl(`https://cognito-identity.${region}.${getDnsSuffix(region)}`)
});
/**
 * A Cognito Identity-specific middleware that disables caching for all requests.
 */
const disableCacheMiddlewareFactory = () => next => async function disableCacheMiddleware(request) {
  request.headers['cache-control'] = 'no-store';
  return next(request);
};
/**
 * A Cognito Identity-specific transfer handler that does NOT sign requests, and
 * disables caching.
 *
 * @internal
 */
const cognitoIdentityTransferHandler = composeTransferHandler(unauthenticatedHandler, [disableCacheMiddlewareFactory]);
/**
 * @internal
 */
const defaultConfig = {
  service: SERVICE_NAME,
  endpointResolver,
  retryDecider: getRetryDecider(parseJsonError),
  computeDelay: jitteredBackoff,
  userAgentValue: getAmplifyUserAgent(),
  cache: 'no-store'
};
observeFrameworkChanges(() => {
  defaultConfig.userAgentValue = getAmplifyUserAgent();
});
/**
 * @internal
 */
const getSharedHeaders = operation => ({
  'content-type': 'application/x-amz-json-1.1',
  'x-amz-target': `AWSCognitoIdentityService.${operation}`
});
/**
 * @internal
 */
const buildHttpRpcRequest = ({
  url
}, headers, body) => ({
  headers,
  url,
  body,
  method: 'POST'
});
export { buildHttpRpcRequest, cognitoIdentityTransferHandler, defaultConfig, getSharedHeaders };
