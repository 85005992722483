import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ErrorComponent } from '../error/error.component';
import { FormErrorService } from '../error/form-error.service';

/**
 * Component for displaying form-wide errors.
 */
@Component({
  selector: 'app-form-group-error',
  templateUrl: './form-group-error.component.html',
  imports: [ErrorComponent],
})
export class FormGroupErrorComponent implements OnInit, OnDestroy {
  private formErrors = inject(FormErrorService);
  private formGroup = inject(FormGroupDirective);

  message?: string;

  private subscription?: Subscription;

  ngOnInit(): void {
    if (this.formGroup.statusChanges) {
      this.subscription = this.formGroup.statusChanges.subscribe(() => {
        this.message = this.formGroup.errors
          ? this.formErrors.describeValidationErrors(this.formGroup.errors)
          : undefined;
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
