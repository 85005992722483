import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withMethods } from '@ngrx/signals';
import {
  removeEntity,
  setAllEntities, setEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { unique } from 'radashi';
import { pipe, switchMap, tap } from 'rxjs';
import { SetRequired } from 'type-fest';

import { Org } from '@rpm/shared/schemas';

import {
  setError,
  setFulfilled,
  setPending,
  withRequestStatus,
} from '../shared/state/request-status.feature';
import { withSelectedEntity } from '../shared/state/selected-entity.feature';

import { OrgService } from './org.service';

export const OrgStore = signalStore(
  { providedIn: 'root' },
  withEntities<Org>(),
  withSelectedEntity(),
  withRequestStatus(),
  withMethods((store, orgService = inject(OrgService)) => ({
    selectOrg(orgId: string) {
      patchState(store, { selectedEntityId: orgId });
    },
    setOrg(org: Org) {
      patchState(store, setEntity(org));
    },
    updateOrg({id, ...changes}: SetRequired<Partial<Org>, 'id'>) {
      patchState(store, updateEntity({id, changes}))
    },
    removeOrg(id: string) {
      patchState(store, removeEntity(id));
    },
    addGroups(groups: string[]) {
      const id = store.selectedEntityId();
      if (id) {
        patchState(
          store,
          updateEntity({
            id,
            changes: (org) => ({
              groups: unique([...(org.groups ?? []), ...groups]).sort((a, b) =>
                a.localeCompare(b),
              ),
            }),
          }),
        );
      }
    },
    removeGroup(group: string) {
      const id = store.selectedEntityId();

      if (id) {
        patchState(
          store,
          updateEntity({
            id,
            changes: (org) => ({
              groups: org.groups?.filter(g => g !== group)
            }),
          }),
        );
      }
    },
    loadAll: rxMethod<void>(
      pipe(
        tap(() => patchState(store, setPending())),
        switchMap(() =>
          orgService.getAll().pipe(
            tapResponse({
              next: (orgs) =>
                patchState(store, setAllEntities(orgs), setFulfilled()),
              error: (err) => {
                patchState(store, setError(err));
              },
            }),
          ),
        ),
      ),
    ),
  })),
);
