import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withMethods } from '@ngrx/signals';
import {
  removeEntity,
  setAllEntities,
  setEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { omit } from 'radashi';
import { filter, pipe, switchMap, tap } from 'rxjs';
import { SetRequired } from 'type-fest';

import { Shop } from '@rpm/shared/schemas';

import { OrgStore } from '../../org/org.store';
import { setLoadedAllInOrgId, withOrgId } from '../../org/with-org-id.feature';
import {
  setError,
  setFulfilled,
  setPending,
  withRequestStatus,
} from '../../shared/state/request-status.feature';
import { ShopService } from '../../shop/shop.service';
import { UserShopStore } from '../users/user-shop.store';

export const ShopStore = signalStore(
  { providedIn: 'root' },
  withEntities<Omit<Shop, 'users'>>(),
  withRequestStatus(),
  withOrgId(),
  withMethods(
    (
      store,
      shopService = inject(ShopService),
      orgStore = inject(OrgStore),
      userShopStore = inject(UserShopStore),
    ) => ({
      setShop({ users, ...shop }: Shop) {
        if (shop.orgId === orgStore.selectedEntityId()) {
          patchState(store, setEntity(shop));
          if (shop.groups) {
            orgStore.addGroups(shop.groups);
          }
          userShopStore.setAllForShop(shop, users ?? {});
        }
      },
      updateShop({
        id,
        orgId,
        ...changes
      }: SetRequired<Partial<Shop>, 'id' | 'orgId'>) {
        if (orgId === orgStore.selectedEntityId()) {
          patchState(store, updateEntity({ id, changes }));
          if (changes.groups) {
            orgStore.addGroups(changes.groups);
          }
          if (changes.users) {
            const shop = store.entityMap()[id];
            userShopStore.setAllForShop(shop, changes.users);
          }
        }
      },
      removeShop({ id, orgId }: Pick<Shop, 'id' | 'orgId'>) {
        if (orgId === orgStore.selectedEntityId()) {
          patchState(store, removeEntity(id));
          userShopStore.removeAllForShop(id);
        }
      },
      loadAllInOrg: rxMethod<string>(
        pipe(
          filter((orgId) => orgId !== store._loadedAllInOrgId()),
          tap(() => patchState(store, setPending())),
          switchMap((orgId) =>
            shopService.getAllInOrg(orgId).pipe(
              tapResponse({
                next: (shops) => {
                  patchState(
                    store,
                    setAllEntities(shops.map((shop) => omit(shop, ['users']))),
                    setFulfilled(),
                    setLoadedAllInOrgId(orgId),
                  );
                  userShopStore.setAll(
                    shops.flatMap((shop) =>
                      Object.entries(shop.users ?? {}).map(
                        ([userId, { name: userName, role }]) => ({
                          userId,
                          userName,
                          shopId: shop.id,
                          shopName: shop.name,
                          role,
                        }),
                      ),
                    ),
                  );
                },
                error: (err) => {
                  patchState(store, setError(err));
                },
              }),
            ),
          ),
        ),
      ),
    }),
  ),
);
