import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import {
  CreateShopBody,
  HistoricSales,
  Shop,
  ShopDetail,
  UpdateHistoricSalesBody,
  UpdateShopBody,
} from '@rpm/shared/schemas';

interface ShopParams {
  orgId: string;
  id: string;
}

export interface CreateShopArgs extends CreateShopBody {
  orgId: string;
}

export interface UpdateShopArgs extends UpdateShopBody, ShopParams {}

const apiUrl = import.meta.env.NG_APP_API_URL;

@Injectable({ providedIn: 'root' })
export class ShopService {
  private http = inject(HttpClient);

  getShop({ orgId, id }: ShopParams) {
    return this.http.get<Shop>(`${apiUrl}/orgs/${orgId}/shops/${id}`);
  }

  getDetails({ orgId, id }: ShopParams) {
    return this.http.get<ShopDetail>(`${apiUrl}/orgs/${orgId}/shops/${id}/details`);
  }

  getHistoricSales({ orgId, id }: ShopParams) {
    return this.http.get<HistoricSales>(
      `${apiUrl}/orgs/${orgId}/shops/${id}/historic-sales`,
    );
  }

  updateHistoricalSales({
    orgId,
    id,
    ...body
  }: ShopParams & UpdateHistoricSalesBody) {
    return this.http.put<void>(
      `${apiUrl}/orgs/${orgId}/shops/${id}/historic-sales`,
      body,
    );
  }

  getAllInOrg(orgId: string) {
    return this.http.get<Shop[]>(`${apiUrl}/orgs/${orgId}/shops`);
  }

  createShop({ orgId, ...body }: CreateShopArgs) {
    return this.http.post<Shop>(`${apiUrl}/orgs/${orgId}/shops`, body);
  }

  updateShop({ orgId, id, ...body }: UpdateShopArgs) {
    return this.http.put<Partial<Shop>>(
      `${apiUrl}/orgs/${orgId}/shops/${id}`,
      body,
    );
  }

  deleteShop({ orgId, id }: { orgId: string; id: string }) {
    return this.http.delete<void>(`${apiUrl}/orgs/${orgId}/shops/${id}`);
  }
}
