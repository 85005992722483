export type Nullable<T> = { [K in keyof T]: T[K] | null };

export function parseNullableFloat(value: string | null) {
  return value === null ? null : parseFloat(value);
}

export function times<N extends number, T>(n: N, cb: (i: number) => T) {
  return Array.from({ length: n }).map((_, i) => cb(i));
}

export function assert(value: unknown, message: string): asserts value {
  if (!value) {
    throw new Error(message);
  }
}
