import { HttpErrorResponse } from '@angular/common/http';
import { get } from 'radashi';

export function getErrorMessage(error: unknown): string {
  if (error instanceof HttpErrorResponse) {
    return getNetworkErrorMessage(error);
  } else {
    const message = get(error, 'message');
    return typeof message === 'string' ? message : String(error);
  }
}

function getNetworkErrorMessage(errorResponse: HttpErrorResponse) {
  return errorResponse.status === 0
    ? 'Server could not be reached'
    : `HTTP ${errorResponse.status}`;
}
