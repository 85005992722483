import { Directive, ElementRef, inject, input, OnChanges } from '@angular/core';

import { times } from '@rpm/shared/utility';

/**
 * Directive that inserts placeholder HTML into a table.
 * This is necessary because there is no other way to show content inside <mat-table> besides modifying the datasource.
 */
@Directive({
  selector: '[appTablePlaceholder]',
})
export class TablePlaceholderDirective implements OnChanges {
  private element = inject<ElementRef<HTMLElement>>(ElementRef);

  enable = input(true, { alias: 'appTablePlaceholder' });
  rows = input(10, { alias: 'appTablePlaceholderRows' });
  cols = input(1, { alias: 'appTablePlaceholderCols' });

  private placeholder?: HTMLElement;

  ngOnChanges(): void {
    if (this.enable()) {
      if (!this.placeholder) {
        const colHtml = times(
          this.cols(),
          (col) =>
            `<td class="mat-mdc-cell px-4" colspan="${col === this.cols() - 1 ? 100 : 1}">` +
            `<div class="text-loading-placeholder"></div>` +
            `</td>`,
        ).join('');

        const rowHtml = `<tr class="mat-mdc-row cursor-default hover:bg-transparent">${colHtml}</tr>`;

        this.placeholder = document.createElement('tbody');
        this.placeholder.insertAdjacentHTML(
          'afterbegin',
          rowHtml.repeat(this.rows()),
        );
        this.element.nativeElement.appendChild(this.placeholder);
        this.element.nativeElement.classList.add('table-placeholder');
      }
    } else if (this.placeholder) {
      this.element.nativeElement.removeChild(this.placeholder);
      this.placeholder = undefined;
      this.element.nativeElement.classList.remove('table-placeholder');
    }
  }
}
